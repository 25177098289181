<template>
        <DataTable :value="revolutionFundData" showGridlines tableStyle="min-width: 50rem">
            <Column field="lineLabel" header="" class="leftHeader">
            </Column>
            <Column field="monthToDate" :minFractionDigits="2">
                <template #header>
                    <span class="p-column-title">End of Previous Year</span>
                </template>
                <template #body="slotProps">
                    <template v-if="slotProps.data.lineLabel != 'Percent Change'">
                        {{ formatCurrency(slotProps.data.lastYear) }}
                    </template>
                    <template v-else>
                        {{ slotProps.data.lastYear }}%
                    </template>
                </template>
            </Column>
            <Column field="monthToDate" :minFractionDigits="2" header="Month To Date">
                <template #body="slotProps">
                    <template v-if="slotProps.data.lineLabel != 'Percent Change'">
                        {{ formatCurrency(slotProps.data.monthToDate) }}
                    </template>
                    <template v-else>
                        {{ slotProps.data.monthToDate }}%
                    </template>
                </template>
            </Column>
            <Column field="quarterToDate" :minFractionDigits="2" header="Quarter To Date">
                <template #body="slotProps">
                    <template v-if="slotProps.data.lineLabel != 'Percent Change'">
                        {{ formatCurrency(slotProps.data.quarterToDate) }}
                    </template>
                    <template v-else>
                        {{ slotProps.data.quarterToDate }}%
                    </template>
                </template>
            </Column>
            <Column field="yearToDate" header="Year To Date">
                <template #body="slotProps">
                    <template v-if="slotProps.data.lineLabel != 'Percent Change'">
                        {{ formatCurrency(slotProps.data.yearToDate) }}
                    </template>
                    <template v-else>
                        {{ slotProps.data.yearToDate }}%
                    </template>
                </template>
            </Column>
            <Column field="sinceInception" header="Since Inception">
                <template #body="slotProps">
                    <template v-if="slotProps.data.lineLabel != 'Percent Change'">
                        {{ formatCurrency(slotProps.data.sinceInception) }}
                    </template>
                    <template v-else>
                        {{ slotProps.data.sinceInception }}%
                    </template>
                </template>
            </Column>
        </DataTable>
</template>

<script>
    export default {
        name: 'RevolutionFund',
        props: ['revolutionFundData', 'dateSelected'],
        data () {
            return {
                beginningYear: ""
            }
            
        },
        methods: {
            formatCurrency(value) {
                return value.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 });
            }
        },
        mounted () {
            // const d = new Date();
            // let lastYear = d.getFullYear() - 1;
            // this.beginningYear = "End of " + lastYear;
        }
     }

     
</script>